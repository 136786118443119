// AboutUs.js
import React from "react";
import './About.css';  // Optional for custom styling if needed
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
<Layout

pageTitle="About Us | ExcelsGenerator"
metaDescription="Learn about ExcelsGenerator, your go-to platform for innovative Excel tools. Our mission is to simplify your workflow and enhance productivity."
metaKeywords="About ExcelsGenerator, Excel tools, productivity, workflow solutions"
canonicalUrl="https://excelsgenerator.com/about-us"
robotsContent="index, follow"
ogTitle="About Us | ExcelsGenerator"
ogDescription="Discover ExcelsGenerator, a leading provider of Excel tools designed to streamline your workflow and improve efficiency."
ogUrl="https://excelsgenerator.com/about-us"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://excelsgenerator.com/about-us",
    "name": "About Us",
    "description": "Learn about ExcelsGenerator, your go-to platform for innovative Excel tools. Our mission is to simplify your workflow and enhance productivity.",
    "publisher": {
        "@type": "Organization",
        "name": "ExcelsGenerator",
        "logo": {
            "@type": "ImageObject",
            "url": "https://excelsgenerator.com/img/logo.png"
        }
    }
}}



>

<div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text text-white">About Us</h1>
		 <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page">About us</li>
      </ol>
    </nav>
       </div>
    </div>
</div>
</div>



<div className="container py-5">
      <div className="row justify-content-center text-center">
        <div className="col-lg-8">
          <p className="lead">
            Welcome to the Excel Generator, your go-to tool for generating a wide variety of Excel formulas, dates, charts, and functions. Our mission is to simplify the way you work with Excel by providing easy-to-use tools that save you time and effort.
          </p>
          <p className="lead">
            Whether you're a professional, student, or data enthusiast, our tools are designed to enhance productivity, making Excel tasks quicker and more efficient.
          </p>
        </div>
      </div>
      
      <div className="row text-center mt-5">
      <h2 className="text-center" >Our Team</h2>
        <div className="col-md-4">
          <div className="card">
            <img src="./img/author.jpeg" alt="Ramjee" className="card-img-top" />
            <div className="card-body">
              <h5 className="card-title">Anurag Chaurasiya</h5>
              
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card">
            <img src="./img/himanshu.jpeg" alt="Himanshu" className="card-img-top" />
            <div className="card-body">
              <h5 className="card-title">shreya maran</h5>
             
             
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card">
            <img src="./img/vikram.jpeg" alt="Vikram" height={'400px'} className="card-img-top" />
            <div className="card-body">
              <h5 className="card-title">damodar tiwari</h5>
              
             
            </div>
          </div>
        </div>
      </div>
    </div>

</Layout>
  );
};

export default AboutUs;
