import React, { useState, useEffect } from "react";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";
import Sidebar from "../Pages/Sidebar";

const InvoiceNumberGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [prefix, setPrefix] = useState("INV");
  const [suffix, setSuffix] = useState("");
  const [currentNumber, setCurrentNumber] = useState(1);
  const [padding, setPadding] = useState(3);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const lastInvoiceNumber = localStorage.getItem("lastInvoiceNumber");
    const storedHistory = JSON.parse(localStorage.getItem("invoiceHistory") || "[]");
    if (lastInvoiceNumber) {
      setCurrentNumber(parseInt(lastInvoiceNumber) + 1);
    }
    setHistory(storedHistory);
  }, []);

  const generateInvoiceNumber = () => {
    const date = new Date();
    const formattedDate = date.toISOString().slice(0, 10).replace(/-/g, "");
    const newInvoiceNumber = `${prefix}${formattedDate}-${String(currentNumber).padStart(padding, '0')}${suffix}`;

    setInvoiceNumber(newInvoiceNumber);
    setHistory([...history, newInvoiceNumber]);
    localStorage.setItem("lastInvoiceNumber", currentNumber);
    localStorage.setItem("invoiceHistory", JSON.stringify([...history, newInvoiceNumber]));
    setCurrentNumber(currentNumber + 1);
  };

  const resetInvoiceNumber = () => {
    setCurrentNumber(1);
    setHistory([]); // Clear the history state
    localStorage.removeItem("lastInvoiceNumber");
    localStorage.removeItem("invoiceHistory"); // Clear the history from local storage
  };
  
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(history.map((inv, index) => ({ Invoice: inv })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Invoices");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
    saveAs(new Blob([buf], { type: "application/octet-stream" }), "invoice_numbers.xlsx");
  };

  return (
   <Layout

pageTitle="Excel Invoice Number Generator | ExcelsGenerator"
metaDescription="Generate unique invoice numbers in Excel with our Excel Invoice Number Generator. Customize formats and ensure sequential numbering for your invoices."
metaKeywords="Excel invoice number generator, unique invoice number, custom invoice format, generate invoice number in Excel"
canonicalUrl="https://excelsgenerator.com/excel-invoice-number-generator"
robotsContent="index, follow"
ogTitle="Excel Invoice Number Generator | ExcelsGenerator"
ogDescription="Generate unique invoice numbers in Excel with our Excel Invoice Number Generator. Customize formats and ensure sequential numbering for your invoices."
ogUrl="https://excelsgenerator.com/excel-invoice-number-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-invoice-number-generator",
  "name": "Excel Invoice Number Generator",
  "description": "Generate unique invoice numbers in Excel with our Excel Invoice Number Generator. Customize formats and ensure sequential numbering for your invoices.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

   
   
   >

   <div className="container-fluid">
    <div className="row">
      <div className="col-md-1"></div>
      <div className="col-md-8 main-section">
        <div className="container">
        <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Excel Invoice Number Generator</li>
                </ol>
        </nav>
        <h1 className='text-center'>Excel Invoice Number Generator</h1>
        <div className="container text-center">
          <div className="row">
            <div className="col-md-12">
            <label htmlFor="prefix" className="form-label label" ><b>Prefix: </b> <span className="text-danger">*</span></label>
              <input
                type="text"
                className="form-control"
                id="input"
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
                placeholder="Enter Prefix"
              />
            </div>
          </div>

          <div className="row input-row">
            <div className="col-md-12">
            <label htmlFor="suffix" className="form-label label" ><b>Suffix: </b> <span className="text-danger">*</span></label>
        <input
          type="text"
          className="form-control"
          id="date"
          value={suffix}
          onChange={(e) => setSuffix(e.target.value)}
          placeholder="Enter Suffix"
        />
            </div>
          </div>

          <div className="row input-row">
            <div className="col-md-12">
            <label htmlFor="Starting" className="form-label label"><b>Starting Number:</b> <span className="text-danger">*</span> </label>
        <input
          type="number"
          className="form-control"
          id="input"
          value={currentNumber}
          onChange={(e) => setCurrentNumber(parseInt(e.target.value))}
          placeholder="Enter Starting Number"
        />
            </div>
          </div>
          <div className="row input-row">
            <div className="col-md-12">
            <label htmlFor="NumberPadding" className="form-label label"><b>Number Padding:</b> <span className="text-danger">*</span> </label>
        <input
          type="number"
          className="form-control"
          id="date"
          value={padding}
          onChange={(e) => setPadding(parseInt(e.target.value))}
        />
            </div>
          </div>

          <div className="row input-row">
            <div className="col-md-12">
            <button  className="cta-button btn btn-lg" onClick={generateInvoiceNumber} style={{ marginTop: "10px" }}>
              Generate Invoice Number
            </button>
            </div>
          </div>

       
            {invoiceNumber && (
          
          <div className="container" style={{marginTop:'3vh'}}>
            <div className="row">
            <strong className="text-center">Generated Invoice Number: {invoiceNumber} </strong> 
            </div>
          </div>
      )}
            

        </div>
                      <div className="container" style={{ marginTop: "20px" }}>
                <h3>Invoice History</h3>
               <div className="table-responsive">
               <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((inv, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{inv}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
               </div>
                <button className="btn btn-danger" onClick={resetInvoiceNumber} >
        Reset
      </button>
      <button style={{marginLeft:'3vh'}} className="btn btn-success" onClick={exportToExcel} >
        Export to Excel
      </button>
              </div>

        </div>

        <div className="container" style={{marginTop:'3vh'}}>
          <div className="row">
            <hr></hr>
            <h3>About Excel Invoice Number Generator</h3>
            <hr></hr>

            <p style={{fontFamily:'sans-serif'}}>An Excel Invoice Number Generator is a powerful tool designed to streamline the invoicing process by automatically generating unique, sequential invoice numbers for each transaction. Invoicing can be a tedious task, especially for businesses that manage multiple clients or issue numerous invoices regularly. Manually assigning invoice numbers can lead to errors such as duplication, missed entries, or inconsistent formats. This is where an automated invoice number generator proves invaluable. By leveraging the functionality of Excel, the generator ensures that each new invoice receives a distinct, incremental number, saving time and reducing the chances of mistakes.</p>

            <p style={{fontFamily:'sans-serif'}} >The tool offers flexibility in customization, allowing users to define their preferred invoice number format. Businesses can add prefixes, such as "INV", followed by the year (e.g., "INV-2024-001"), or customize the numbering sequence to match their internal workflow. This feature makes tracking invoices more straightforward, especially when dealing with high volumes of transactions. With automation, users no longer need to remember the last invoice number issued; the generator takes care of this, automatically updating the number for each new entry.</p>

            <h4><b>How It Works:</b></h4>
           
           <div style={{fontFamily:'sans-serif'}} >
           <p> The user specifies the format and the starting invoice number.</p>
           <p> The tool automatically increments the number for each new invoice, based on the previous invoice.</p>
           <p>Users can choose to add prefixes (like "INV" or the year) or suffixes to the invoice numbers for more organization and clarity.</p>
           <p> The generated numbers can easily be exported or used directly within Excel to manage invoices.</p>
           </div>

            <p style={{fontFamily:'sans-serif'}} >In addition to providing consistency, the Excel Invoice Number Generator helps in better organization, ensuring that no two invoices share the same number. This is crucial for accounting, auditing, and record-keeping purposes, as it makes it easier to locate and reference specific invoices when needed. By integrating this tool into Excel, businesses can manage their invoicing more efficiently, leveraging the widespread use of Excel for other accounting tasks. Ultimately, this generator helps businesses save time, improve accuracy, and enhance the overall professionalism of their invoicing system.</p>

           

           
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <Sidebar/>
      </div>
    </div>
   </div>
    
   </Layout>
  );
};

export default InvoiceNumberGenerator;
