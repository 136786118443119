import React from 'react';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <Layout
      pageTitle="Contact Us | ExcelsGenerator"
      metaDescription="Get in touch with the ExcelsGenerator team for any inquiries, suggestions, or feedback regarding our Excel tools."
      metaKeywords="Contact ExcelsGenerator, support, Excel tool inquiries, suggestions, feedback"
      canonicalUrl="https://excelsgenerator.com/contact-us"
      robotsContent="index, follow"
      ogTitle="Contact Us | ExcelsGenerator"
      ogDescription="Reach out to us with any questions or feedback regarding our Excel tools at ExcelsGenerator."
      ogUrl="https://excelsgenerator.com/contact-us"
      ogImage="https://excelsgenerator.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "ContactPage",
        "url": "https://excelsgenerator.com/contact-us",
        "name": "Contact Us",
        "description": "Get in touch with the ExcelsGenerator team for any inquiries, suggestions, or feedback regarding our Excel tools.",
        "publisher": {
          "@type": "Organization",
          "name": "ExcelsGenerator",
          "logo": {
            "@type": "ImageObject",
            "url": "https://excelsgenerator.com/img/logo.png"
          }
        }
      }}
    >

<div className="image-aboutus-banner" >
   <div className="container">
 
    <div className="row">
        <div className="col-md-12">
         <h1 className="lg-text text-white">Contact Us</h1>
		 <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/"  className="text-white font-weight-bold text-decoration-none">Home</Link></li>
        <li className="breadcrumb-item active text-decoration-none" aria-current="page">Contact us</li>
      </ol>
    </nav>
       </div>
    </div>
</div>
</div>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center" style={{marginTop:'4vh'}}>
              <h1>Contact Us</h1>
              <p>
                If you have any questions, feedback, or suggestions regarding our Excel tools, feel free to reach out to us via the form below.
              </p>
            </div>

            <div className="col-md-12 google-form mb-4">
              <iframe
                title="Contact-form"
                src="https://docs.google.com/forms/d/e/1FAIpQLSds9GOrKtUXkky9_llyque6mMFvLIqhXx1n2kG9AsbdmfLW6w/viewform?embedded=true"
                width="100%"  // Set width to 100% for responsiveness
                height="1028"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              >
                Loading…
              </iframe>
            </div>

            <div className="col-md-12 text-center">
              <p>
                You can also reach us via email at <a href="mailto:anuragchaurasiya041@gmail.com">anuragchaurasiya041@gmail.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
