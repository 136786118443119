// src/SkuGenerator.js
import React, { useState , useEffect } from 'react';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar';

const SkuGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [skuPrefix, setSkuPrefix] = useState(''); // New state for SKU prefix
  const [productName, setProductName] = useState('');
  const [category, setCategory] = useState('');
  const [quantity, setQuantity] = useState('');
  const [skuList, setSkuList] = useState([]);

  const generateSKU = () => {
    // Generate SKU based on product details and SKU prefix
    const sku = `${skuPrefix.toUpperCase()}-${category.substr(0, 3).toUpperCase()}-${productName.substr(0, 3).toUpperCase()}-${quantity}`;
    return sku;
  };

  const addSKU = () => {
    if (skuPrefix && productName && category && quantity) {
      const sku = generateSKU();
      const newSkuEntry = {
        sku,
        productName,
        category,
        quantity: parseInt(quantity),
      };
      
      setSkuList([...skuList, newSkuEntry]);

      // Clear inputs
      setSkuPrefix('');
      setProductName('');
      setCategory('');
      setQuantity('');
    } else {
      alert('Please fill out all fields.');
    }
  };



  const copyToClipboard = (item) => {
    const textToCopy = `${item.sku} - ${item.productName} (Category: ${item.category}, Quantity: ${item.quantity})`;

    // Copy the text to the clipboard
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        // Success feedback
        alert('SKU copied to clipboard!');
      })
      .catch((err) => {
        // Error feedback
        console.error('Failed to copy text: ', err);
        alert('Copy failed. Please try again.');
      });
  };

  return (
 <Layout 

pageTitle="Excel SKU Generator | ExcelsGenerator"
metaDescription="Easily generate unique SKU numbers in Excel with our Excel SKU Generator. Streamline inventory management by creating custom SKUs for your products."
metaKeywords="Excel SKU generator, generate SKU numbers, custom SKU generator, inventory management"
canonicalUrl="https://excelsgenerator.com/excel-sku-generator"
robotsContent="index, follow"
ogTitle="Excel SKU Generator | ExcelsGenerator"
ogDescription="Easily generate unique SKU numbers in Excel with our Excel SKU Generator. Streamline inventory management by creating custom SKUs for your products."
ogUrl="https://excelsgenerator.com/excel-sku-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-sku-generator",
  "name": "Excel SKU Generator",
  "description": "Easily generate unique SKU numbers in Excel with our Excel SKU Generator. Streamline inventory management by creating custom SKUs for your products.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

 
 
 >
     <div className='container-fluid'>
     <div className='row'>
      <div className='col-md-1'></div>
      <div className='col-md-8 main-section'>
      <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
        <li className="breadcrumb-item"><Link to="/tools" style={{textDecoration:'none' , color:'black'}}>Tools</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Excel Sku Generator</li>
      </ol>
        </nav>
        <h1 className='text-center'>Excel Sku Generator</h1>

        <div className='container text-center'>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='skuPrefix' className='form-label label'> <b>SKU Prefix:</b> <span className='text-danger'>*</span> </label>
        <input
            type="text"
            className='form-control'
            id='input'
            value={skuPrefix}
            onChange={(e) => setSkuPrefix(e.target.value)}
            placeholder='Enter Your SKU Prefix'
          />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='ProductName' className='form-label label'> <b>Product Name:</b> <span className='text-danger'>*</span></label>
        <input
            type="text"
            className='form-control'
            value={productName}
            id='date'
            onChange={(e) => setProductName(e.target.value)}
            placeholder='Enter Product Name'
          />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='category' className='form-label label'> <b>Category :</b><span className='text-danger'>*</span></label>
        <input
            type="text"
            className='form-control'
            id='date'
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder='Enter Category'
          />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='quantity' className='form-label label' ><b>Quantity:</b> <span className='text-danger'>*</span></label>
        <input
            type="number"
            className='form-control'
            id='date'
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder='Enter Quantity'
          />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <button className='cta-button btn btn-lg' onClick={addSKU}>Generate</button>
            </div>
          </div>
        </div>

     
        <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
          <ul>
        {skuList.map((item, index) => (
          <li key={index}>
            {item.sku} - {item.productName} (Category: {item.category}, Quantity: {item.quantity})
            {/* Add copy button */}

            <button className='btn btn-success' onClick={() => copyToClipboard(item)} style={{ marginLeft: '10px' }}>
              Copy
            </button>
          </li>
        ))}
      </ul>
          </div>
        </div>
        </div>

        <div className='container'>
          <div className='row'>
            <hr></hr>
            <h3>About Excel Sku Generator</h3>
            <hr></hr>
            <p style={{fontFamily:'sans-serif'}}>A SKU (Stock Keeping Unit) Generator for Excel is a tool that simplifies the process of creating unique SKUs for products. SKUs are critical for managing inventory, tracking sales, and organizing products across various retail, e-commerce, and supply chain operations. Manually generating SKUs for thousands of items can be cumbersome, time-consuming, and prone to errors, which is why an automated SKU generator is invaluable.</p>

            <h4><b>What Is an SKU?</b></h4>
            <p style={{fontFamily:'sans-serif'}}>A Stock Keeping Unit (SKU) is a unique alphanumeric code assigned to a product to identify and track it in a business’s inventory. SKUs typically consist of a combination of letters and numbers that represent various characteristics of a product.</p>
            <p style={{fontFamily:'sans-serif'}}>SKUs are essential in retail, warehousing, and e-commerce environments to help streamline inventory tracking, manage stock levels, and enhance order fulfillment processes.</p>

            <h4><b>Why Use an Excel SKU Generator?</b></h4>
            <p style={{fontFamily:'sans-serif'}}> Instead of manually creating unique SKUs for each product, an Excel SKU Generator can automatically generate hundreds or thousands of SKUs in seconds</p>
            <p style={{fontFamily:'sans-serif'}}>Using an SKU generator ensures that all SKUs follow a consistent format, making them easy to interpret and reducing the likelihood of errors.</p>

            <p style={{fontFamily:'sans-serif'}}> As your business grows, the SKU generator can handle larger and more complex product ranges, making it easier to keep track of expanding inventories.</p>

            <p style={{fontFamily:'sans-serif'}}>Since many businesses use Excel to manage their inventory, the ability to export generated SKUs directly into an Excel file allows for seamless integration with existing systems and workflows.</p>

            <h4><b>Steps to Use an Excel SKU Generator</b></h4>

            <p style={{fontFamily:'sans-serif'}}>Before generating SKUs, decide on the format for the SKUs based on your business needs. A typical SKU format could include the product category, brand, size, and color.</p>

            <p style={{fontFamily:'sans-serif'}}>Enter the necessary product details into the generator, such as product category, brand name, size, and any other relevant attributes.</p>

            <p style={{fontFamily:'sans-serif'}}>Once the product details are input, the generator will automatically create SKUs based on the specified format. For example, a red medium-sized T-shirt from Nike might generate an SKU like CLTH-NIKE-MED-RED.</p>

            <p style={{fontFamily:'sans-serif'}}>After generating the SKUs, you can export the data directly into an Excel file. The Excel sheet will include columns for product details alongside the newly generated SKUs, making it easy to manage and track products.</p>

            <h4><b>Applications of an SKU Generator</b></h4>

            <p style={{fontFamily:'sans-serif'}} >Online retailers managing thousands of products can use the SKU generator to create and organize unique SKUs for each item, ensuring easy searchability and categorization in their e-commerce systems.</p>

            <p style={{fontFamily:'sans-serif'}}>Warehouses and fulfillment centers can rely on SKUs to manage stock levels, track incoming and outgoing goods, and facilitate quicker order processing.</p>

            <p style={{fontFamily:'sans-serif'}}>Brick-and-mortar stores use SKUs to manage products across multiple locations, ensuring that inventory counts are accurate and up to date.</p>


            <h5><b>Conclusion</b></h5>

            <p style={{fontFamily:'sans-serif'}}>An Excel SKU Generator is an essential tool for businesses that manage a large inventory of products. By automating the creation of unique, meaningful SKUs, this tool improves efficiency, reduces errors, and ensures that businesses can scale smoothly. Whether you're running an e-commerce store, a warehouse, or a retail chain, using an SKU generator simplifies the complex process of inventory management and allows you to focus on growing your business.</p>

          </div>
        </div>

      </div>
      <div className='col-md-3'>
      <Sidebar/>
      </div>
     </div>

      
    </div>
 </Layout>
  );
};

export default SkuGenerator;
