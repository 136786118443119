import React from 'react'
import { useState , useEffect} from 'react';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';
import './main.css'
import './Responsive.css'
import Sidebar from '../Pages/Sidebar';

const ExcelCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [type, setType] = useState('integer');
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(100);
  const [precision, setPrecision] = useState(2);
  const [length, setLength] = useState(10); // Length for alphanumeric and hex
  const [startDate, setStartDate] = useState('2024-01-01'); // Start date for random dates
  const [endDate, setEndDate] = useState('2024-12-31'); // End date for random dates
  const [randomNumber, setRandomNumber] = useState(null);
  const [formula, setFormula] = useState('');
  
  const generateRandomNumber = () => {
    let result;
    let formulaStr;

    switch (type) {
      case 'integer':
        result = Math.floor(Math.random() * (max - min + 1)) + min;
        formulaStr = `=RANDBETWEEN(${min}, ${max})`;
        break;
      case 'decimal':
        result = (Math.random() * (max - min)) + min;
        result = parseFloat(result.toFixed(precision));
        formulaStr = `=RAND()*(${max}-${min}) + ${min}`;
        break;
      case 'decimal01':
        result = Math.random();
        result = parseFloat(result.toFixed(precision));
        formulaStr = `=RAND()`;
        break;
      case 'boolean':
        result = Math.random() < 0.5;
        formulaStr = `=IF(RAND() < 0.5, TRUE, FALSE)`;
        break;
      case 'customRange':
        result = Math.floor(Math.random() * (max - min + 1)) + min;
        formulaStr = `=RANDBETWEEN(${min}, ${max})`;
        break;
      case 'date':
        const start = new Date(startDate);
        const end = new Date(endDate);
        const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        result = randomDate.toISOString().slice(0, 10); // Format as YYYY-MM-DD
        formulaStr = `=DATE(RANDBETWEEN(YEAR(${startDate}), YEAR(${endDate})), RANDBETWEEN(1, 12), RANDBETWEEN(1, 28))`;
        break;
      case 'alphanumeric':
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        result = Array.from({ length }).map(() => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
        formulaStr = `=CONCAT(MID("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", RANDBETWEEN(1, 62), 1), MID("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", RANDBETWEEN(1, 62), 1), MID("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", RANDBETWEEN(1, 62), 1), MID("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", RANDBETWEEN(1, 62), 1), MID("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", RANDBETWEEN(1, 62), 1))`;
        break;
      case 'hexadecimal':
        result = '#' + Array.from({ length }).map(() => '0123456789ABCDEF'.charAt(Math.floor(Math.random() * 16))).join('');
        formulaStr = `=CONCAT("#", DEC2HEX(RANDBETWEEN(0, 16777215), 6))`;
        break;
      default:
        result = null;
        formulaStr = '';
    }

    setRandomNumber(result);
    setFormula(formulaStr);
  };

  const copyFormulaToClipboard = () => {
    if (formula) {
      navigator.clipboard.writeText(formula).then(
        () => alert('Formula copied to clipboard!'),
        (err) => alert('Failed to copy formula. Please try again.')
      );
    }
  };

  return (
  
    <Layout 

pageTitle="Excel Random Number Generator | ExcelsGenerator"
metaDescription="Generate random numbers quickly and easily with our Excel Random Number Generator. Customize the range and download your results in Excel format."
metaKeywords="Excel random number generator, generate random numbers in Excel, random number generator tool, random number generator Excel"
canonicalUrl="https://excelsgenerator.com/excel-random-number-generator"
robotsContent="index, follow"
ogTitle="Excel Random Number Generator | ExcelsGenerator"
ogDescription="Generate random numbers quickly and easily with our Excel Random Number Generator. Customize the range and download your results in Excel format."
ogUrl="https://excelsgenerator.com/excel-random-number-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-random-number-generator",
  "name": "Excel Random Number Generator",
  "description": "Generate random numbers quickly and easily with our Excel Random Number Generator. Customize the range and download your results in Excel format.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    
    >
    <div className='container-fluid'>
    <div className='row'>
      <div className='col-md-1'>

      </div>
      <div className='col-md-8 main-section'>
      <div className='container'>
        <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
        <li className="breadcrumb-item"><Link to="/tools" style={{textDecoration:'none' , color:'black'}}>Tools</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Excel Random Number Generator</li>
      </ol>
        </nav>

        <h1 className='text-center'>Excel Random Number Generator</h1>

        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-12'>
            <label htmlFor='type' className='form-label label'>
          <b>Type: </b> <span className='text-danger'>*</span>   </label>
          <select  id='date' className='form-control' value={type} onChange={(e) => setType(e.target.value)}>
            <option value="integer">Integer (Between Two Values)</option>
            <option value="decimal">Decimal (Between Two Values)</option>
            <option value="decimal01">Decimal (Between 0 and 1)</option>
            <option value="boolean">Boolean (True/False)</option>
            <option value="customRange">Custom Range Integer</option>
            <option value="date">Date (Between Two Dates)</option>
            <option value="alphanumeric">Alphanumeric String</option>
            <option value="hexadecimal">Hexadecimal Number</option>
          </select>
    
            </div>
          </div>

          {(type === 'decimal' || type === 'customRange') && (
     <>
     <div className='row input-row'>
         <div className='col-md-12'>
         <label htmlFor='min' className='form-label label' >
           <b> Min Value:</b> <span className='text-danger'>*</span>
          </label>
          <input type="number" className='form-control'  id='input' value={min} onChange={(e) => setMin(Number(e.target.value))} />
         </div>
         </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='max' className='form-label label' >
                     <b> Max Value:</b> <span className='text-danger'>*</span></label>
                    <input type="number" className='form-control' id='date' value={max} onChange={(e) => setMax(Number(e.target.value))} />
            </div>
          </div>
     </>
        
      )}
            
      {(type === 'decimal') && (
        <div className='row input-row'>
          <div className='col-md-12'>
          <label htmlFor='precision' className='form-label label' >
            <b>Precision:</b> <span className='text-danger'>*</span>
          </label>
            <input type="number" id='input' className='form-control' value={precision} min="0" onChange={(e) => setPrecision(Number(e.target.value))} />
          </div>
        </div>
      )}


      {(type === 'date') && (
        <>
        <div className='row input-row'>
          <div className='col-md-12'>
          <label htmlFor='StartDate' className='form-label label' >
           <b> Start Date:</b> <span className='text-danger'>*</span>
          </label>
            <input type="date" className='form-control' id='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          </div>
        </div>

        <div className='row input-row'>
          <div className='col-md-12'>
          <label htmlFor='EndDate' className='form-label label' >
            <b>End Date:</b> <span className='text-danger' >*</span>
          </label>
            <input type="date" className='form-control' id='input' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </div>
        </div>
       
        </>
      )}
               
      {(type === 'alphanumeric' || type === 'hexadecimal') && (

        <div className='row input-row'>
              <div className='col-md-12'>
          <label htmlFor='length' className='form-label label' >
            <b>Length:</b> <span className='text-danger'>*</span>
          </label>
            <input type="number" id='date' className='form-control' value={length} min="1" onChange={(e) => setLength(Number(e.target.value))} />
              </div>
            </div>
      )}
         
         <div className='row input-row'>
          <div className='col-md-12'>
          <button className='cta-button btn btn-lg' onClick={generateRandomNumber}>Generate</button>
          </div>
         </div>
        </div>
        {randomNumber !== null && (
        <div style={{marginTop:'4vh'}}>
          <h2>Generated Number: {randomNumber.toString()}</h2>
          <p>Excel Formula: <code>{formula}</code></p>
          <button className='btn btn-success' onClick={copyFormulaToClipboard} style={{ marginLeft: '10px' }}>Copy Formula</button>
        </div>
      )}
      </div>
      <div className='container' style={{marginTop:'4vh'}} >
        <div className='row'>
          <hr></hr>
          <h3>About Excel Random Number Generator</h3>
          <hr></hr>
          <p style={{fontFamily:'sans-serif'}} >Random number generation is a critical component in various applications, including simulations, statistical analysis, and gaming. Microsoft Excel provides built-in functions to generate random numbers, allowing users to create random datasets, conduct experiments, and perform statistical analyses with ease. In this article, we will explore how to use Excel to generate random numbers effectively.</p>

          <h4><b></b></h4>
          <p style={{fontFamily:'sans-serif'}} >Excel offers two primary functions for generating random numbers: RAND() and RANDBETWEEN(). Each serves a different purpose based on the type of random numbers you wish to generate.</p>
          <p style={{fontFamily:'sans-serif'}} >The RAND() function generates a random decimal number between 0 and 1. This function updates and produces a new random number every time the worksheet recalculates.</p>
          <p style={{fontFamily:'sans-serif'}} >The RANDBETWEEN() function generates a random integer between two specified numbers. Unlike RAND(), this function allows you to define the boundaries for the random numbers.</p>

          <h4><b>Generating Unique Random Numbers</b></h4>
          <p style={{fontFamily:'sans-serif'}} >You can generate random integers in one column and check for duplicates in another column. If a duplicate is found, you can regenerate the number to ensure uniqueness.</p>
          <p style={{fontFamily:'sans-serif'}}>If you are using Excel 365 or later, there are advanced features available that simplify the process of generating unique random numbers. You can leverage certain functions that allow for the easy sorting of numbers in random order without duplicates.</p>
          <h4><b>Applications of Random Number Generation</b></h4>
          <p style={{fontFamily:'sans-serif'}}> In statistics, random samples are essential for conducting analyses and hypothesis testing. Researchers often use random sampling to avoid bias in their results.</p>
          <p style={{fontFamily:'sans-serif'}}>Random numbers are commonly used in games for scenarios such as rolling dice, shuffling cards, or determining outcomes in gameplay. This randomness enhances the unpredictability and excitement of games.</p>
          <p style={{fontFamily:'sans-serif'}} > In modeling and simulations, random numbers can be employed to simulate real-world scenarios and variability, allowing analysts to predict outcomes and assess risks effectively.</p>

          <h5><b>Conclusion</b></h5>
          <p style={{fontFamily:'sans-serif'}} >Excel's random number generation capabilities are powerful tools for various applications, including data analysis, simulations, and gaming. By understanding and utilizing the RAND() and RANDBETWEEN() functions, users can easily generate random numbers to meet their specific needs. Whether for statistical analysis, gaming scenarios, or simulations, mastering these functions can enhance your Excel experience and enable you to harness the power of randomness in your projects.</p>

        </div>


      </div>
      </div>
      <div className='col-md-3'>
       <Sidebar/>
      </div>
    </div>
    </div>
  </Layout>
  )
}

export default ExcelCalculator
