import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './main.css'
import './Responsive.css'
import { Line, Bar, Pie, Doughnut, Radar, Bubble, Scatter } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  BubbleController,
  RadarController,
  ScatterController
} from 'chart.js';
import * as XLSX from 'xlsx';
import Layout from '../Layout/Layout';
import Sidebar from '../Pages/Sidebar';

// Register necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  BubbleController,
  RadarController,
  ScatterController
);

const ChartGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [chartType, setChartType] = useState('line');
  const [labels, setLabels] = useState('');
  const [dataPoints, setDataPoints] = useState('');
  const [chartTitle, setChartTitle] = useState('Chart Title');
  const [legendPosition, setLegendPosition] = useState('top');
  const [xAxisTitle, setXAxisTitle] = useState('X Axis Title');
  const [yAxisTitle, setYAxisTitle] = useState('Y Axis Title');
  const [borderColor] = useState('blue');
  const [backgroundColor] = useState('rgba(0, 0, 255, 0.2)');
  const [pointRadius] = useState(5);
  const [lineWidth] = useState(1);
  const [tooltipBackgroundColor] = useState('rgba(0, 0, 0, 0.8)');
  const [tooltipBorderColor] = useState('rgba(0, 0, 0, 0.8)');
  const [padding] = useState(20);
  const [gridLineColor] = useState('rgba(0, 0, 0, 0.1)');
  const [showChart, setShowChart] = useState(false);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef(null);

  const handleGenerateChart = () => {
    const labelsArray = labels ? labels.split(',').map(label => label.trim()) : [];
    const dataArray = dataPoints ? dataPoints.split(',').map(point => parseFloat(point.trim())) : [];

    setChartData({
      labels: labelsArray,
      datasets: [
        {
          label: 'Data',
          data: dataArray,
          borderColor: borderColor,
          backgroundColor: backgroundColor,
          pointRadius: pointRadius,
          borderWidth: lineWidth
        }
      ]
    });

    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          position: legendPosition
        },
        title: {
          display: true,
          text: chartTitle,
          font: {
            size: 20
          },
          padding: {
            bottom: padding
          }
        },
        tooltip: {
          backgroundColor: tooltipBackgroundColor,
          borderColor: tooltipBorderColor,
          borderWidth: 1,
          callbacks: {
            label: (context) => `${context.dataset.label}: ${context.raw}`
          }
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: xAxisTitle,
            font: {
              size: 16
            }
          },
          grid: {
            color: gridLineColor
          }
        },
        y: {
          title: {
            display: true,
            text: yAxisTitle,
            font: {
              size: 16
            }
          },
          grid: {
            color: gridLineColor
          }
        }
      }
    });

    setShowChart(true);
  };

  const handleExportExcel = () => {
    if (!chartData.labels || !chartData.datasets) return;

    const ws = XLSX.utils.json_to_sheet([
      { Labels: 'Data', 'Chart Data': JSON.stringify(chartData) }
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'chart_data.xlsx');
  };

  const handleSaveImage = () => {
    if (chartRef.current) {
      const chartImage = chartRef.current.toBase64Image();
      const link = document.createElement('a');
      link.href = chartImage;
      link.download = 'chart.png';
      link.click();
    }
  };

  return (
    <Layout 

pageTitle="Excel Chart Generator | ExcelsGenerator"
metaDescription="Generate custom charts in Excel with our Excel Chart Generator. Input your data and create professional Excel charts instantly."
metaKeywords="Excel chart generator, chart creator, Excel charts, data visualization, generate charts in Excel"
canonicalUrl="https://excelsgenerator.com/excel-chart-generator"
robotsContent="index, follow"
ogTitle="Excel Chart Generator | ExcelsGenerator"
ogDescription="Generate custom charts in Excel with our Excel Chart Generator. Input your data and create professional Excel charts instantly."
ogUrl="https://excelsgenerator.com/excel-chart-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-chart-generator",
  "name": "Excel Chart Generator",
  "description": "Generate custom charts in Excel with our Excel Chart Generator. Input your data and create professional Excel charts instantly.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
            <div className='container'>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Excel Chart Generator</li>
                </ol>
              </nav>
              <h1 className='text-center main-heading'>Excel Chart Generator</h1>
              <div className='container text-center'>
              
                <div className='row input-row'>
                  <div className='col-md-12'>
                    <label>
                      <b>Select Chart Type:</b>
                      <span className='text-danger'>*</span>
                    </label>
                    <select className="form-control" id='date' value={chartType} onChange={(e) => setChartType(e.target.value)}>
                      <option value="line">Line Chart</option>
                      <option value="bar">Bar Chart</option>
                      <option value="pie">Pie Chart</option>
                      <option value="doughnut">Doughnut Chart</option>
                      <option value="radar">Radar Chart</option>
                      <option value="bubble">Bubble Chart</option>
                      <option value="scatter">Scatter Chart</option>
                      <option value="mixed">Mixed Chart</option>
                    
                    </select>
                  </div>

                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='Label' className='form-label label'>
                        <b>Enter Labels (comma-separated):</b>
                        <span className='text-danger'>*</span>
                      </label>
                      <input
                        id='input'
                        className="form-control"
                        type="text"
                        value={labels}
                        onChange={(e) => setLabels(e.target.value)}
                      />
                    </div>
                  </div>
                   
                   <div className='row input-row'>
                  <div className='col-md-12'>
                    <label htmlFor='Data' className='form-label label'>
                      <b>Enter Data Point (comma-separated):</b>
                      <span className='text-danger'>*</span>
                    </label>
                    <input
                        className="form-control"
                        id='date'
                        type="text"
                        value={dataPoints}
                        onChange={(e) => setDataPoints(e.target.value)}
                      />
                  </div>
                   </div>

                  <div className='row input-row'>
                  <div className='col-md-12'>
                    <label htmlFor='ChartTitle' className='form-label label'>
                    <b>Enter Chart Title:</b>
                    <span className='text-danger'>*</span>
                      </label>
                    <input
                        className="form-control"
                        id='date'
                        type="text"
                        value={chartTitle}
                        onChange={(e) => setChartTitle(e.target.value)}
                      />
                  </div>
                  </div>

                  <div className='row input-row'>
                  <div className='col-md-12'>
                    <label htmlFor='Legend' className='form-label label'>
                     <b> Legend Position:</b>
                     <span className='text-danger'>*</span>
                    </label>
                    <select id='date' className="form-control" value={legendPosition} onChange={(e) => setLegendPosition(e.target.value)}>
                        <option value="top">Top</option>
                        <option value="bottom">Bottom</option>
                        <option value="left">Left</option>
                        <option value="right">Right</option>
                      </select>
                  </div>
                  </div>

               <div className='row input-row'>
               <div className='col-md-12'>
                    <label htmlFor='XAxis' className='form-label label'>
                     <b> X Axis Title:</b>
                     <span className='text-danger'>*</span>
                    </label>
                    <input
                        className="form-control"
                        id='date'
                        type="text"
                        value={xAxisTitle}
                        onChange={(e) => setXAxisTitle(e.target.value)}
                      />
                  </div>
               </div>

               <div className='row input-row'>
                <div className='col-md-12'>
                  <label htmlFor='YAxis' className='form-label label'>
                    <b>Y Axis Title:</b>
                    <span className='text-danger'>*</span>
                  </label>
                  <input
                      className="form-control"
                      id='input'
                      type="text"
                      value={yAxisTitle}
                      onChange={(e) => setYAxisTitle(e.target.value)} // Update Y Axis Title
                    />
                </div>
              </div>
              <div className='row input-row'>
                <div className='col-md-12'>
                <button className="btn btn-primary mt-4" onClick={handleGenerateChart}>
                  Generate Chart
                </button>
                </div>
              </div>
              </div>
            </div>

            {showChart && (
              <div className='container' style={{marginTop:"3rem"}}>
                <div className='chart-container'>
                  {chartType === 'line' && <Line ref={chartRef} data={chartData} options={chartOptions} />}
                  {chartType === 'bar' && <Bar ref={chartRef} data={chartData} options={chartOptions} />}
                  {chartType === 'pie' && <Pie ref={chartRef} data={chartData} options={chartOptions} />}
                  {chartType === 'doughnut' && <Doughnut ref={chartRef} data={chartData} options={chartOptions} />}
                  {chartType === 'radar' && <Radar ref={chartRef} data={chartData} options={chartOptions} />}
                  {chartType === 'bubble' && <Bubble ref={chartRef} data={chartData} options={chartOptions} />}
                  {chartType === 'scatter' && <Scatter ref={chartRef} data={chartData} options={chartOptions} />}
                  {chartType === 'mixed' && (
                    <Line
                      ref={chartRef}
                      data={{
                        labels: chartData.labels,
                        datasets: [
                          {
                            label: 'Line Data',
                            data: chartData.datasets[0].data,
                            borderColor: 'red',
                            fill: false,
                            tension: 0.1,
                          },
                          {
                            label: 'Bar Data',
                            data: chartData.datasets[0].data,
                            backgroundColor: 'blue',
                            type: 'bar',
                          }
                        ],
                      }}
                      options={chartOptions}
                    />
                  )}
                  {/* Add area chart and histogram rendering here if needed */}
                </div>
                <div className="text-center">
                  <button className="btn btn-primary mt-4" onClick={handleExportExcel}>
                    Export to Excel
                  </button>
                  <button style={{marginLeft:"2vh"}} className="btn btn-secondary mt-4" onClick={handleSaveImage}>
                    Save as Image
                  </button>
                </div>
              </div>
            )}

            <div className='container'>
              <div className='row' style={{marginTop:'50px'}}></div>
              <hr></hr>
              <h3 className='text-center text-black'> About Excel Chart Generator</h3>
              <hr></hr>
              <p style={{fontFamily:"sans-serif"}}>In today’s data-driven world, visual representation of information is crucial to understanding and decision-making. Excel charts are a powerful tool for transforming raw data into meaningful insights. However, creating charts manually in Excel can be time-consuming, especially if you need to frequently update or modify your charts. This is where an Excel Chart Generator comes into play, offering an easy, user-friendly way to generate professional charts without hassle.</p>

              <h3><b>What is an Excel Chart Generator?</b></h3>
              <p style={{fontFamily:"sans-serif"}}>An Excel Chart Generator is a tool that allows users to quickly create a variety of charts, such as line charts, bar charts, pie charts, and more, using input data. Rather than manually entering formulas or chart configurations in Excel, users can simply input their data into the tool, select the chart type, and instantly generate a visual chart. This saves time and reduces the chances of errors.</p>
              <h4><b>Key Features of an Excel Chart Generator</b></h4>
              <p style={{fontFamily:"sans-serif"}}>
              An Excel Chart Generator is a versatile tool that supports a variety of chart types, such as line, bar, pie, doughnut, radar, bubble, and scatter charts. Each chart serves different purposes, from showing trends over time to visualizing relationships between data sets. </p>
            <p style={{fontFamily:"sans-serif"}}>  The tool allows users to customize various chart elements like titles, axis labels, , and legends, ensuring that charts meet presentation standards and preferences. One of its standout features is real-time responsiveness, enabling users to see dynamic updates as they modify their input data, which allows for experimentation with different visual styles. </p>
              
              <p style={{fontFamily:"sans-serif"}}>the tool provides flexible export options, letting users download charts as images or directly export them to Excel for further use. For more advanced analysis, it supports multiple datasets, making it ideal for comparative data visualization.
              </p>
              <h4><b>Applications of Excel Chart Generators</b></h4>
              <p style={{fontFamily:'sans-serif '}}> Generate sales reports, project progress charts, and other business-critical data visualizations with minimal effort</p>
              <p style={{fontFamily:'sans-serif'}}>Easily create charts for academic papers, presentations, and reports. Whether you're presenting survey results or scientific data, an Excel chart generator makes the process seamless.</p>
              <p style={{fontFamily:"sans-serif"}}>Create visual representations of customer demographics, campaign performance, and market trends to inform strategic decisions.</p>
              <p style={{fontFamily:'sans-serif'}}>Use charts to visualize stock performance, cash flow, and other financial metrics to present a clear picture of company health.</p>

              <h5><b>Conclusion</b></h5> 
              <p style={{fontFamily:"sans-serif"}}>The Excel Chart Generator is a powerful tool that simplifies the process of creating professional charts, saving you time and effort while ensuring accuracy and visual appeal. Whether you are a student, business analyst, researcher, or marketer, this tool can significantly enhance the way you present data. By offering flexibility, customization, and real-time previews, it helps you generate insightful charts that support effective decision-making.</p>
            </div>
          </div>
          <div className='col-md-3'>
            <Sidebar/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChartGenerator;
