import React, { useState , useEffect} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar';

const ExcelFormulaGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formulaType, setFormulaType] = useState('SUM');
  const [options, setOptions] = useState({});
  const [formula, setFormula] = useState('');
  const [copied, setCopied] = useState(false);

  // Function to generate formula
  const generateFormula = (type, options) => {
    switch (type) {
      case 'SUM':
        return `=SUM(${options.range})`;
      case 'AVERAGE':
        return `=AVERAGE(${options.range})`;
      case 'VLOOKUP':
        return `=VLOOKUP(${options.lookup_value}, ${options.table_array}, ${options.col_index_num}, ${options.range_lookup})`;
      case 'IF':
        return `=IF(${options.condition}, ${options.value_if_true}, ${options.value_if_false})`;
      case 'CONCATENATE':
        return `=CONCATENATE(${options.text1}, ${options.text2})`;
      case 'COUNTIF':
        return `=COUNTIF(${options.range}, ${options.criteria})`;
      case 'INDEX':
        return `=INDEX(${options.array}, ${options.row_num}, ${options.column_num || ''})`;
      case 'MATCH':
        return `=MATCH(${options.lookup_value}, ${options.lookup_array}, ${options.match_type || 0})`;
      case 'LEFT':
        return `=LEFT(${options.text}, ${options.num_chars})`;
      case 'RIGHT':
        return `=RIGHT(${options.text}, ${options.num_chars})`;
      case 'MID':
        return `=MID(${options.text}, ${options.start_num}, ${options.num_chars})`;
      case 'MAX':
        return `=MAX(${options.range})`;
      case 'MIN':
        return `=MIN(${options.range})`;
      default:
        return '';
    }
  };

  const handleGenerate = () => {
    const generatedFormula = generateFormula(formulaType, options);
    setFormula(generatedFormula);
    setCopied(false);
  };

  const handleInputChange = (e) => {
    setOptions({
      ...options,
      [e.target.name]: e.target.value,
    });
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Hide after 2 seconds
  };

  return (
    <Layout 

pageTitle="Excel Formula Generator | ExcelsGenerator"
metaDescription="Create and customize Excel formulas easily with our Excel Formula Generator. Generate formulas for various calculations and download them in Excel format."
metaKeywords="Excel formula generator, generate Excel formulas, custom Excel formulas, Excel calculations tool"
canonicalUrl="https://excelsgenerator.com/excel-formula-generator"
robotsContent="index, follow"
ogTitle="Excel Formula Generator | ExcelsGenerator"
ogDescription="Create and customize Excel formulas easily with our Excel Formula Generator. Generate formulas for various calculations and download them in Excel format."
ogUrl="https://excelsgenerator.com/excel-formula-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-formula-generator",
  "name": "Excel Formula Generator",
  "description": "Create and customize Excel formulas easily with our Excel Formula Generator. Generate formulas for various calculations and download them in Excel format.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-8 main-section">
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/tools" style={{ textDecoration: 'none', color: 'black' }}>
                      All Tools
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Excel Formula Generator
                  </li>
                </ol>
              </nav>
              <h1 className="text-center">Excel Formula Generator</h1>
              <div className="container text-center">
                <div className="row input-row">
                  <div className="col-md-12">
                    <label htmlFor="Formula" className="form-label label">
                      <b>Select Formula :</b>
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      id="input"
                      className="form-control"
                      onChange={(e) => setFormulaType(e.target.value)}
                      value={formulaType}
                    >
                      <option value="SUM">SUM</option>
                      <option value="AVERAGE">AVERAGE</option>
                      <option value="VLOOKUP">VLOOKUP</option>
                      <option value="IF">IF</option>
                      <option value="CONCATENATE">CONCATENATE</option>
                      <option value="COUNTIF">COUNTIF</option>
                      <option value="INDEX">INDEX</option>
                      <option value="MATCH">MATCH</option>
                      <option value="LEFT">LEFT</option>
                      <option value="RIGHT">RIGHT</option>
                      <option value="MID">MID</option>
                      <option value="MAX">MAX</option>
                      <option value="MIN">MIN</option>
                    </select>
                  </div>
                </div>

                {/* Dynamic Inputs based on Formula Type */}
                {['SUM', 'MAX', 'MIN'].includes(formulaType) && (
                  <div className="row input-row">
                    <div className="col-md-12">
                      <input
                        name="range"
                        id="input"
                        className="form-control"
                        placeholder="Range (e.g., A1:A10)"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}
                {formulaType === 'MID' && (
  <>
   <div className='row input-row'>
    <div className='col-md-12'>
    <input
      name="text"
      id='input'
      className="form-control"
      placeholder='Text or Cell (e.g., "HelloWorld" or A1)'
      onChange={handleInputChange}
    />
    </div>
   </div>
    <div className='row input-row'>
      <div className='col-md-12'>
      <input
      name="start_num"
      id='date'
      className="form-control"
      placeholder="Start Position (e.g., 2)"
      onChange={handleInputChange}
    />
      </div>
    </div>
   <div className='row input-row'>
    <div className='col-md-12'>
    <input
      name="num_chars"
      id='input'
      className="form-control"
      placeholder="Number of Characters (e.g., 3)"
      onChange={handleInputChange}
    />
    </div>
   </div>
  </>
)}

{formulaType === 'AVERAGE' && (
  <div className='row input-row'>
    <div className='col-md-12'>
    <input
    name="range"
     id='input'
    className="form-control"
    placeholder="Range (e.g., A1:A10 or A1,B1,C1)"
    onChange={handleInputChange}
  />
    </div>
  </div>
)}

{formulaType === 'CONCATENATE' && (
  <>
   <div className='row input-row'>
    <div className='col-md-12'>
    <input
      name="text1"
      id='input'
      className="form-control"
      placeholder='First Text or Cell (e.g., "Hello" or A1)'
      onChange={handleInputChange}
    />
    </div>
   </div>
    <div className='row input-row'>
      <div className='col-md-12'>
      <input
      name="text2"
      id='date'
      className="form-control"
      placeholder='Second Text or Cell (e.g., "World" or B1)'
      onChange={handleInputChange}
    />
      </div>
    </div>
  </>
)}
                {formulaType === 'VLOOKUP' && (
                  <>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="lookup_value"
                          className="form-control"
                          id="date"
                          placeholder="Lookup Value"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="table_array"
                          id="date"
                          placeholder="Table Array (e.g., A1:B10)"
                          className="form-control"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="col_index_num"
                          id="input"
                          placeholder="Column Index Number"
                          className="form-control"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="range_lookup"
                          className="form-control"
                          id="date"
                          placeholder="Range Lookup (TRUE or FALSE)"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </>
                )}
                {formulaType === 'MATCH' && (
   <>
   <div className='row input-row'>
  <div className='col-md-12'>
  <input
      name="lookup_value"
      id='input'
      className="form-control"
      placeholder="Lookup Value (e.g., A1 or 'Item')"
      onChange={handleInputChange}
    />
  </div>
  </div>
 
   <div className='row input-row'>
    <div className='col-md-12'>
    <input
      name="lookup_array"
      id='date'
      className="form-control"
      placeholder="Lookup Array (e.g., B1:B10)"
      onChange={handleInputChange}
    />
    </div>
   </div>
   <div className='row input-row'>
    <div className='col-md-12'>
    <input
      name="match_type"
      id='input'
      className="form-control"
      placeholder="Match Type (1, 0, or -1)"
      onChange={handleInputChange}
    />
    </div>
   </div>
   </>
 
)}

{formulaType === 'LEFT' && (
  <>
   <div className='row input-row'>
    <div className='col-md-12'>
    <input
      name="text"
      id='input'
      className="form-control"
      placeholder='Text or Cell (e.g., "Hello" or A1)'
      onChange={handleInputChange}
    />
    </div>
   </div>
  <div className='row input-row'>
    <div className='col-md-12'>
    <input
      name="num_chars"
      id='date'
      className="form-control"
      placeholder="Number of Characters (e.g., 3)"
      onChange={handleInputChange}
    />
    </div>
  </div>
  </>
)}


{formulaType === 'RIGHT' && (
  <>
  <div className='row input-row'>
    <div className='col-md-12'>
    <input
      name="text"
      id='input'
      className="form-control"
      placeholder='Text or Cell (e.g., "World" or A1)'
      onChange={handleInputChange}
    />
    </div>
  </div>
   <div className='row input-row'>
    <div className='col-md-12'>
    <input
      name="num_chars"
      id='date'
      className="form-control"
      placeholder="Number of Characters (e.g., 4)"
      onChange={handleInputChange}
    />
    </div>
   </div>
  </>
)}



                {formulaType === 'IF' && (
                  <>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="condition"
                          id="input"
                          placeholder="Condition (e.g., A1>10)"
                          className="form-control"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="value_if_true"
                          className="form-control"
                          id="input"
                          placeholder="Value if True"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="value_if_false"
                          id="input"
                          className="form-control"
                          placeholder="Value if False"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </>
                )}

                {formulaType === 'COUNTIF' && (
                  <>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="range"
                          id="input"
                          className="form-control"
                          placeholder="Range (e.g., A1:A10)"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="criteria"
                          id="date"
                          className="form-control"
                          placeholder="Criteria (e.g., '>5')"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </>
                )}

                {formulaType === 'INDEX' && (
                  <>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="array"
                          id="input"
                          className="form-control"
                          placeholder="Array (e.g., A1:C10)"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="row_num"
                          id="date"
                          className="form-control"
                          placeholder="Row Number"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <input
                          name="column_num"
                          id="input"
                          className="form-control"
                          placeholder="Column Number (Optional)"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row input-row">
                  <div className="col-md-12">
                    <button className="cta-button btn btn-lg" onClick={handleGenerate}>
                      Generate Formula
                    </button>
                  </div>
                </div>

                {formula && (
                  <>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <label htmlFor="generatedFormula" className="form-label label">
                          <b>Generated Formula:</b>
                        </label>
                        <textarea
                          id="generatedFormula"
                          className="form-control"
                          rows="3"
                          readOnly
                          value={formula}
                        />
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                        <CopyToClipboard text={formula} onCopy={handleCopy}>
                          <button className="btn btn-success">Copy Formula</button>
                        </CopyToClipboard>
                        {copied && <span className="text-success ml-2">Copied!</span>}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='container' style={{marginTop:'4vh'}} >
              <div className='row'>
                <hr></hr>
                <h3><b>About Excel Formula Generator</b></h3>
                <hr></hr>
                <p style={{fontFamily:'sans-serif'}} >An Excel Formula Generator is a powerful tool designed to simplify the creation and use of Excel formulas by automating the process of generating complex calculations. For individuals who frequently work with data in Excel, mastering formulas can be a daunting task, especially for those unfamiliar with the intricate syntax and logic. The Excel Formula Generator bridges this gap by allowing users to input basic parameters or select options, and the tool then automatically generates the correct Excel formula based on those inputs.</p>
                <p style={{fontFamily:'sans-serif'}} >With the Excel Formula Generator, users can effortlessly create formulas such as SUM, AVERAGE, IF, VLOOKUP, and even more advanced ones like INDEX-MATCH or CONCATENATE. This tool enhances productivity by reducing the time spent on manual formula construction and minimizing the chances of errors in writing or applying formulas. It is particularly beneficial for beginners or intermediate users who may not fully understand Excel's syntax but still need accurate and efficient calculations in their spreadsheets.</p>

                <p style={{fontFamily:'sans-serif'}} >
                Additionally, an Excel Formula Generator can often come with features such as copy buttons for easy insertion into Excel, dynamic previews of formulas, and customization options that allow users to tweak the formula to meet their specific needs. Whether for financial modeling, data analysis, or routine business calculations, this tool provides a user-friendly interface that demystifies the process of working with formulas, enabling users to focus more on data insights rather than the mechanics of Excel itself.
                </p>
                <p style={{fontFamily:'sans-serif'}} >Overall, an Excel Formula Generator is an invaluable resource for anyone looking to streamline their workflow, reduce the learning curve associated with Excel formulas, and produce more accurate data analysis in less time.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <Sidebar/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ExcelFormulaGenerator;
