import React , {useState ,useEffect} from 'react'
import Layout from '../Layout/Layout'
import { Link } from 'react-router-dom';
import './main.css'
import './Responsive.css'
import Sidebar from '../Pages/Sidebar';
const DateGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [numberOfDates, setNumberOfDates] = useState(1);
    const [dateFormat, setDateFormat] = useState('YYYY-MM-DD');
    const [generatedDates, setGeneratedDates] = useState([]);
  
    const generateRandomDate = (start, end) => {
      const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
      return formatDate(date);
    };
  
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
  
      switch (dateFormat) {
        case 'YYYY-MM-DD':
          return `${year}-${month}-${day}`;
        case 'MM/DD/YYYY':
          return `${month}/${day}/${year}`;
        case 'DD/MM/YYYY':
          return `${day}/${month}/${year}`;
        case 'MMMM DD, YYYY':
          return `${date.toLocaleString('default', { month: 'long' })} ${day}, ${year}`;
        case 'DD MMMM YYYY':
          return `${day} ${date.toLocaleString('default', { month: 'long' })} ${year}`;
        default:
          return date.toISOString().split('T')[0];
      }
    };
  
    const handleGenerateDates = () => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const dates = [];
  
      for (let i = 0; i < numberOfDates; i++) {
        dates.push(generateRandomDate(start, end));
      }
  
      setGeneratedDates(dates);
    };
  
    const handleCopyDates = () => {
      navigator.clipboard.writeText(generatedDates.join('\n'));
      alert('Dates copied to clipboard!');
    };
  
  return (
    <Layout 

pageTitle="Excel Random Date Generator | ExcelsGenerator"
metaDescription="Generate random dates in Excel with our Excel Random Date Generator. Set custom date ranges and create random dates for your Excel sheets."
metaKeywords="Excel random date generator, random date generator, Excel date generator, generate random dates in Excel"
canonicalUrl="https://excelsgenerator.com/excel-random-date-generator"
robotsContent="index, follow"
ogTitle="Excel Random Date Generator | ExcelsGenerator"
ogDescription="Generate random dates in Excel with our Excel Random Date Generator. Set custom date ranges and create random dates for your Excel sheets."
ogUrl="https://excelsgenerator.com/excel-random-date-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-random-date-generator",
  "name": "Excel Random Date Generator",
  "description": "Generate random dates in Excel with our Excel Random Date Generator. Set custom date ranges and create random dates for your Excel sheets.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    
    >
        <div className='container-fluid'>
        <div className='row'>
        <div className='col-md-1'>

        </div>
        <div className='col-md-8 main-section'>
        <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/tools" style={{textDecoration:'none' , color:'black'}}>Tools</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Excel Random Date Generator</li>
                </ol>
                    </nav>
                    <div className='container text-center'>
                    <h1 className='text-center'>Excel  Random Date Calculator</h1>
                    <div className='row input-row' >
                      <div className='col-md-12'>
                      <label htmlFor='startDate' className='form-label label'><b>Start Date:</b><span className='text-danger'>*</span></label>
                      <input type="date" 
                      id='date'
                      className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                      </div>
                    </div>
                    <div className='row input-row'>
                      <div className='col-md-12'>
                      <label htmlFor='endDate' className='form-label label'><b>End Date:</b><span className='text-danger'>*</span></label>
                      <input type="date" className='form-control'
                      id='date' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                      </div>
                    </div>

                    <div className='row input-row'>
                   <div className='col-md-12'>
                   <label htmlFor='NumberofDate'><b>Number of Dates:</b><span className='text-danger'>*</span></label>
                    <input type="number" className='form-control' 
                    id='input'
                    value={numberOfDates} onChange={(e) => setNumberOfDates(e.target.value)} min="1" />
                   </div>
                    </div>

                    <div className='row input-row'>
                      <div className='col-md-12'>
                      <label htmlFor='select' className='form-label label'><b>Date Format:</b><span className='text-danger'>*</span></label>
                      <select value={dateFormat} 
                      id='input' className='form-control' onChange={(e) => setDateFormat(e.target.value)}>
                        <option value="YYYY-MM-DD">YYYY-MM-DD</option>
                        <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                        <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                        <option value="MMMM DD, YYYY">MMMM DD, YYYY</option>
                        <option value="DD MMMM YYYY">DD MMMM YYYY</option>
                      </select>
                      </div>
                    </div>
                    <div className='row input-row'>
                      <div className='col-md-12'>
                      <button className="cta-button btn btn-lg"  type="button" onClick={handleGenerateDates}>Generate Dates</button>
                      </div>
                    </div>
        </div>


        {generatedDates.length > 0 && (
        <div className='container'>
        <hr>
        </hr>
          <h3>Generated Dates:</h3> <br></br>
          <div className='row'>
            <div className='col-md-12'>
            <pre>{generatedDates.join('\n')}</pre>
            <button className='btn btn-success' type="button" onClick={handleCopyDates}>Copy Dates</button>
            </div>
          </div>
        </div>
      )}

      <div className='container' style={{marginTop:'4vh'}}>
        <div className='row'>
          <hr></hr>
           <h3>About Excel Random Date Calculator</h3>
          <hr></hr>
          <p style={{fontFamily:'sans-serif'}}>Generating random dates in Excel can be useful for various tasks, such as creating sample data for testing, simulations, or data analysis. Excel provides multiple functions and methods that allow users to calculate random dates within a specific range. In this guide, we'll explore how to generate random dates effectively in Excel.</p>
          <h4><b>Why Generate Random Dates?</b></h4>
          <p style={{fontFamily:'sans-serif'}}>When you need to simulate a dataset with dates for testing software, analytics tools, or reports.</p>
          <p style={{fontFamily:'sans-serif'}}>Random dates are useful for creating realistic scenarios in simulations or models, such as financial forecasting or scheduling.</p>
          <p style={{fontFamily:'sans-serif'}}>Educators and trainers use random dates in exercises to teach Excel functions, date manipulations, and formulas.</p>

          <h4><b>How Excel Handles Dates</b></h4>
          <p style={{fontFamily:'sans-serif'}}>Before diving into random date generation, it's essential to understand how Excel treats dates. Excel stores dates as sequential serial numbers. For example, January 1, 1900, is represented as 1, and January 2, 1900, is 2. Each subsequent day increases the serial number by one. This allows Excel to perform calculations using dates.</p>

          <h4><b>Limitations of Random Date Generation in Excel</b></h4>
          <p style={{fontFamily:'sans-serif'}}>You need to manually ensure that the generated dates fall within a valid range (e.g., ensuring the correct number of days in a month).</p>
          <p style={{fontFamily:'sans-serif'}}>For very large datasets, generating random dates using formulas may slow down performance.</p>

          <p style={{fontFamily:'sans-serif'}}>Excel's random date generator is a powerful tool for simulating data, testing models, and analyzing time-based scenarios. By using functions like RANDBETWEEN() and DATE(), you can easily create random dates within any specified range. Whether you're working on data analysis, simulations, or creating training datasets, Excel makes it easy to generate random dates to suit your needs.</p>
        </div>
      </div>
        </div>
        <div className='col-md-3'>
         <Sidebar/>
        </div>
      
      
      
      
     
     
        </div>
      </div>
    </Layout>
  )
}

export default DateGenerator
