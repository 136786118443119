import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { saveAs } from 'file-saver';
import Layout from '../Layout/Layout'
import { Link } from 'react-router-dom';
import Sidebar from '../Pages/Sidebar';

const QRCodeGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const [text, setText] = useState('');
    const [qrCodeURL, setQrCodeURL] = useState('');
  
    const handleInputChange = (e) => {
      setText(e.target.value);
    };
  
    const handleGenerateQRCode = () => {
      setQrCodeURL(text);
    };
  
    const handleDownloadQRCode = () => {
      const canvas = document.getElementById('qr-code-canvas');
      if (canvas) {
        canvas.toBlob((blob) => {
          saveAs(blob, 'qr-code.png');
        });
      }
    };
  return (
    <Layout 

pageTitle="Excel QR Code Generator | ExcelsGenerator"
metaDescription="Generate QR codes directly in Excel with our Excel QR Code Generator. Customize and download QR codes for your data with ease."
metaKeywords="Excel QR code generator, generate QR code in Excel, QR code generator, Excel tool"
canonicalUrl="https://excelsgenerator.com/excel-qr-code-generator"
robotsContent="index, follow"
ogTitle="Excel QR Code Generator | ExcelsGenerator"
ogDescription="Generate QR codes directly in Excel with our Excel QR Code Generator. Customize and download QR codes for your data with ease."
ogUrl="https://excelsgenerator.com/excel-qr-code-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-qr-code-generator",
  "name": "Excel QR Code Generator",
  "description": "Generate QR codes directly in Excel with our Excel QR Code Generator. Customize and download QR codes for your data with ease.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

    
    
    >
       <div className='container-fluid'>
       <div className='row'>
       <div className='col-md-1'>
          
          </div>
          <div className='col-md-8 main-section'>
            <div className='container text-center'>
  
            <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                      <li className="breadcrumb-item"><Link to="/tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Excel  QR Code Generator</li>
                  </ol>
          </nav>
  
          <h1 className='text-center'>Excel  QR Code Generator</h1>
        
            <div className='row input-row'>
              <div className='col-md-12'>
                 <label htmlFor='QRCodetext' className='form-label label' ><b>Enter text (number or link ) :</b> <span className='text-danger'>*</span></label>
                 <input
                  type="text"
                  className='form-control'
                  id='input'
                  value={text}
                  onChange={handleInputChange}
                  placeholder="Enter text , link , number to generate QR code"
                />
  
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
              <button className='cta-button btn btn-lg' onClick={handleGenerateQRCode} >
            Generate QR Code
          </button>
              </div>
            </div>
            </div>
            
            <div className='container'>
              <div className='row input-row'>
              {qrCodeURL && (
          <div className="qr-code-content " id='date'>
            <QRCodeCanvas
              id="qr-code-canvas"
              value={qrCodeURL}
              size={256}
              includeMargin={true}
              fgColor="#000000"
              bgColor="#ffffff"
            />
            <br />
            <button  style={{marginTop:"2vh"}} onClick={handleDownloadQRCode} className="btn btn-success">
              Download QR Code
            </button>
          </div>
        )}
              </div>
            </div>


            <div className='container' style={{marginTop:"2vh"}}>
              <div className='row'>
              <hr></hr>
                <h3 className='text-center text-black'>About QR Code Generator</h3>
                <hr></hr>
                <p style={{fontFamily:'sans-serif'}}>An Excel QR code generator is a valuable tool that allows users to create QR codes directly from data stored in Excel spreadsheets. QR codes, short for Quick Response codes, are two-dimensional barcodes that can store a variety of data, such as URLs, contact information, or text. They have become widely used in industries such as marketing, retail, and logistics due to their ability to quickly convey information when scanned by smartphones or other QR code readers. With an Excel QR code generator, users can transform spreadsheet data into QR codes, streamlining processes like inventory tracking, event management, or sharing web links.</p>

                <p style={{fontFamily:"sans-serif"}}>The integration of QR code generation within Excel provides a convenient solution for businesses and individuals who handle large datasets. By converting data from Excel cells into scannable codes, users can automate tasks such as creating unique QR codes for each product in an inventory or generating codes for event registration. </p>
                <p style={{fontFamily:'sans-serif'}}>This eliminates the need for manual creation of QR codes, saving time and reducing the possibility of errors. Excel QR code generators are also highly customizable, allowing users to adjust the size, format, and type of QR code generated, making it easy to tailor the output to specific needs.</p>

                <p style={{fontFamily:'sans-serif'}}> Excel QR code generators offer versatility by supporting various types of data inputs, including URLs, email addresses, phone numbers, and even entire blocks of text. This makes them ideal for businesses looking to add QR codes to product labels, invoices, or digital marketing materials. As more industries embrace digital transformation, the ability to quickly generate QR codes from existing Excel data enhances efficiency and improves accessibility to information. In short, an Excel QR code generator provides a seamless way to link the power of spreadsheet data management with the convenience of QR code technology.</p>

                <h4><b>Advantages of an Excel QR Code Generator</b></h4>
                <p style={{fontFamily:'sans-serif'}}>The Excel QR code generator automates the process of generating multiple QR codes from spreadsheet data, saving time and effort. Instead of manually creating QR codes one by one, users can generate them in bulk from data entries, improving workflow efficiency.</p>
                <p style={{fontFamily:'sans-serif'}}>automating QR code generation directly from Excel data, the chance of human error is significantly reduced. Users no longer need to copy and paste data into external QR code generators, which can lead to mistakes.</p>
                <p style={{fontFamily:'sans-serif'}}>QR codes can store various types of data such as URLs, email addresses, contact information, and text. The Excel QR code generator allows users to create codes for diverse use cases like product labeling, event registration, payment systems, and more.</p>
                <p style={{fontFamily:'sans-serif'}}>Many Excel QR code generators allow for easy customization of the QR code’s size, color, and error correction levels. This flexibility ensures that the generated QR codes meet specific branding or practical needs.</p>
                <h4><b>Features of an Excel QR Code Generator</b></h4>
                <p style={{fontFamily:'sans-serif'}}>The generator can convert various types of data into QR codes, including text, URLs, email addresses, and even Wi-Fi credentials. This makes it suitable for many different applications.</p>
                <p style={{fontFamily:'sans-serif'}}>QR codes generated in Excel can usually be saved or exported in image formats (like PNG or SVG), making them easy to insert into documents, labels, or marketing materials.</p>

                <h5><b>Summary</b></h5>
                <p style={{fontFamily:'sans-serif'}}>QR code generation is a powerful and efficient feature in Excel QR code generators that saves time and effort when dealing with large datasets.</p>
              </div>
            </div>

          </div>
          <div className='col-md-3'>
             <Sidebar/>
          </div>
       </div>
       </div>
     
    
    </Layout>
  )
}

export default QRCodeGenerator
