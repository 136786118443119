// src/EpochTimeGenerator.js

import React, { useState , useEffect} from 'react';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import Layout from '../Layout/Layout';
import Sidebar from '../Pages/Sidebar';

const timeZones = [
  { label: 'UTC', value: 'UTC' },
  { label: 'GMT', value: 'GMT' },
  { label: 'PST', value: 'America/Los_Angeles' },
  { label: 'MST', value: 'America/Denver' },
  { label: 'CST', value: 'America/Chicago' },
  { label: 'EST', value: 'America/New_York' },
  { label: 'CET', value: 'Europe/Berlin' },
  { label: 'IST', value: 'Asia/Kolkata' },
  { label: 'JST', value: 'Asia/Tokyo' },
  { label: 'AEST', value: 'Australia/Sydney' },
  // Add more time zones as needed
];

const EpochTimeGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [dateTime, setDateTime] = useState('');
  const [epochTime, setEpochTime] = useState(null);
  //eslint-disable-next-line
  const [convertedDate, setConvertedDate] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState('UTC');

  const handleGenerateEpoch = () => {
    const date = new Date(dateTime + 'Z'); // Append 'Z' to treat it as UTC
    const epoch = Math.floor(date.getTime() / 1000); // Convert milliseconds to seconds
    setEpochTime(epoch);
  };

 

  const handleExportToExcel = () => {
    const data = [
      { DateTime: dateTime, EpochTime: epochTime },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Epoch Time');

    // Export to Excel
    XLSX.writeFile(workbook, 'Epoch_Time.xlsx');
  };

  return (
   <Layout 

pageTitle="Excel Epoch Time Generator | ExcelsGenerator"
metaDescription="Convert dates to epoch time with our Excel Epoch Time Generator. Easily generate Unix timestamps in Excel for any given date and time."
metaKeywords="Excel epoch time generator, epoch time generator, Excel Unix timestamp generator, generate epoch time in Excel"
canonicalUrl="https://excelsgenerator.com/excel-epoch-time-generator"
robotsContent="index, follow"
ogTitle="Excel Epoch Time Generator | ExcelsGenerator"
ogDescription="Convert dates to epoch time with our Excel Epoch Time Generator. Easily generate Unix timestamps in Excel for any given date and time."
ogUrl="https://excelsgenerator.com/excel-epoch-time-generator"
ogImage="https://excelsgenerator.com/img/logo.png"
schemaData={{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "url": "https://excelsgenerator.com/excel-epoch-time-generator",
  "name": "Excel Epoch Time Generator",
  "description": "Convert dates to epoch time with our Excel Epoch Time Generator. Easily generate Unix timestamps in Excel for any given date and time.",
  "publisher": {
    "@type": "Organization",
    "name": "ExcelsGenerator",
    "logo": {
      "@type": "ImageObject",
      "url": "https://excelsgenerator.com/img/logo.png"
    }
  }
}}

   
   >

<div className='container-fluid'>
    <div className='row'>
      <div className='col-md-1'></div>
      <div className='col-md-8 main-section'>
        <div className='container'>
        <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
        <li className="breadcrumb-item"><Link to="/tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Excel Epoch Time Generator</li>
      </ol>
        </nav>
        <h1 className='text-center'>Excel Epoch Time Generator</h1>

        <div className='container text-center'>
          <div className='row input-row'>
        
        <div className='col-md-12'>
        <input
          type="datetime-local"
          id='input'
          className='form-control'
          value={dateTime}
          onChange={(e) => setDateTime(e.target.value)}
          style={styles.input}
        />
        </div>
      
          </div>

          <div className='row input-row'>
            <div className='col-md-12'>
            <select  className='form-control'  id='date' value={selectedTimeZone} onChange={(e) => setSelectedTimeZone(e.target.value)} style={styles.select}>
          {timeZones.map((zone) => (
            <option key={zone.value} value={zone.value}>{zone.label}</option>
          ))}
        </select>
            </div>
          </div>

          <div className='row input-row'>
            <div className='col-md-12'>
              
      <button onClick={handleGenerateEpoch} className='cta-button btn btn-lg' >Generate Epoch Time</button>
            </div>
          </div>
        </div>

        
      {epochTime !== null && (
        <div style={styles.result}>
          <h4>Epoch Time:</h4>
          <p>{epochTime}</p>
          <button onClick={handleExportToExcel} className='btn btn-success' disabled={!epochTime}>Export to Excel</button>
        </div>
      )}
        
        </div>

        <div className='container' style={{marginTop:'4vh'}}>
          <div className='row'>
            <hr></hr>
            <h3>About Excel Epoch Time Generator</h3>
            <hr></hr>
            <p style={{fontFamily:'sans-serif'}} >
            Epoch time, also known as Unix time or POSIX time, represents the number of seconds that have elapsed since January 1, 1970 (00:00:00 UTC). It is commonly used in computer systems and programming languages to store and manipulate dates and times. In this article, we will explore how to generate and convert dates to Epoch time in Excel.
            </p>
            <h4><b>What is Epoch Time?</b></h4>
            <p style={{fontFamily:'sans-serif'}}>Epoch time is a system for tracking time based on a specific starting point known as the Unix Epoch (January 1, 1970). Rather than using a traditional date format like "YYYY-MM-DD," epoch time expresses time as the total number of seconds that have passed since that reference point. This makes it particularly useful in computing and data processing, as it is simple to store, compare, and manipulate.</p>

            <h4><b>Excel's Date and Time System</b></h4>
            <p style={{fontFamily:'sans-serif'}}>
            Before generating Epoch time in Excel, it's important to understand how Excel handles dates. Excel stores dates as serial numbers, with January 1, 1900, being day 1. Excel’s date system starts at 1900, which is different from Unix’s starting point of 1970.
            </p>
            <p style={{fontFamily:'sans-serif'}}>Excel Date Serial Number: Each day after January 1, 1900, adds 1 to the serial number. For example, January 2, 1900, is represented as 2.</p>
            <h4><b>Why Use Epoch Time?</b></h4>
            <p style={{fontFamily:'sans-serif'}}>
            Many APIs and programming languages use epoch time because it's an efficient and language-agnostic way of tracking time.
            Storing dates in epoch time ensures consistency across systems. Unix-based systems often store timestamps in epoch time for accuracy and simplicity.
            </p>

            <h5><b>Conclusion</b></h5>
            <p style={{fontFamily:'sans-serif'}}>Understanding how to work with epoch time in Excel is essential for anyone dealing with time-sensitive data in systems that use Unix time. By converting Excel dates into epoch time, you can seamlessly integrate Excel with other tools and databases that require Unix timestamps. Whether you're preparing datasets or analyzing time-based information, Excel’s date functions combined with epoch time can provide powerful solutions for data handling.</p>
          </div>
        </div>
      </div>
      <div className='col-md-3'>
        <Sidebar/>
      </div>
    </div>
     
     
      


    </div>

   </Layout>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '500px',
    margin: 'auto',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    backgroundColor: '#f9f9f9',
  },
  inputGroup: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginRight: '10px',
    padding: '5px',
    flex: 1,
  },
  select: {
    marginRight: '10px',
    padding: '5px',
  },
  button: {
    marginTop: '10px',
    padding: '10px 15px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  result: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#e7f3fe',
    borderRadius: '5px',
  },
};

export default EpochTimeGenerator;
